export default {
  getRoles (state) {
    const roles = []
    for (const k in state.roles) {
      roles.push({
        label: state.roles[k].description,
        value: state.roles[k].id
      })
    }
    return roles
  }
}
