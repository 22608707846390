var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "employee-new-modal" } },
    [
      _c("webcam-photo", {
        ref: "webcam",
        on: { snapShot: _vm.onValueChanged },
      }),
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: { title: _vm.title, active: _vm.localShow },
          on: {
            "update:active": function ($event) {
              _vm.localShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "flex items-start flex-col lg:flex-row" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "cursor-pointer p-2 vs-con-loading__container mt-6",
                      staticStyle: {
                        position: "absolute",
                        width: "200px",
                        height: "200px",
                      },
                      attrs: { id: "photo-loading" },
                      on: {
                        mouseover: function ($event) {
                          _vm.mouseOver = true
                        },
                        mouseleave: function ($event) {
                          _vm.mouseOver = false
                        },
                        click: _vm.loadCamera,
                      },
                    },
                    [
                      _vm.mouseOver
                        ? _c("feather-icon", {
                            staticStyle: {
                              "margin-left": "55px",
                              "margin-top": "54px",
                            },
                            attrs: {
                              svgClasses:
                                "h-16 w-16 stroke-current text-success",
                              icon: "CameraIcon",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("img", {
                    staticClass: "mt-6 mr-8 rounded h-56 w-56",
                    attrs: { src: _vm.employee.photo || _vm.noUserPhoto },
                    on: { load: _vm.closeLoading },
                  }),
                  _c("div", { staticClass: "w-full" }, [
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.initial",
                                value: "required|alpha_spaces",
                                expression: "'required|alpha_spaces'",
                                modifiers: { initial: true },
                              },
                            ],
                            staticClass: "w-full mt-0",
                            attrs: {
                              label: "Nome",
                              "data-vv-as": "Nome",
                              name: "name",
                            },
                            model: {
                              value: _vm.employee.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.employee, "name", $$v)
                              },
                              expression: "employee.name",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("name"),
                                  expression: "errors.has('name')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [_vm._v(_vm._s(_vm.errors.first("name")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                        [
                          _c("vs-input", {
                            staticClass: "w-full mt-0",
                            attrs: { label: "Credencial" },
                            model: {
                              value: _vm.employee.credential,
                              callback: function ($$v) {
                                _vm.$set(_vm.employee, "credential", $$v)
                              },
                              expression: "employee.credential",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-0" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v("Funções"),
                          ]),
                          _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.initial",
                                value: "required",
                                expression: "'required'",
                                modifiers: { initial: true },
                              },
                            ],
                            attrs: {
                              reduce: (option) => option.value,
                              multiple: "",
                              "data-vv-as": "Funções",
                              name: "roles",
                              clearable: false,
                              options: _vm.roles,
                              placeholder: "Selecione",
                              dir: _vm.$vs.rtl ? "rtl" : "ltr",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "no-options",
                                fn: function ({}) {
                                  return [
                                    _vm._v(
                                      "\n                    Desculpe, nenhum resultado encontrado.\n                  "
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.employee.roles,
                              callback: function ($$v) {
                                _vm.$set(_vm.employee, "roles", $$v)
                              },
                              expression: "employee.roles",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("roles"),
                                  expression: "errors.has('roles')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [_vm._v(_vm._s(_vm.errors.first("roles")))]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["###.###.###-##", "##.###.###/####-##"],
                                expression:
                                  "['###.###.###-##','##.###.###/####-##']",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "cpf",
                                expression: "'cpf'",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              label: "CPF",
                              "data-vv-as": "CPF",
                              name: "cpf",
                            },
                            model: {
                              value: _vm.employee.cpf,
                              callback: function ($$v) {
                                _vm.$set(_vm.employee, "cpf", $$v)
                              },
                              expression: "employee.cpf",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("cpf"),
                                  expression: "errors.has('cpf')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [_vm._v(_vm._s(_vm.errors.first("cpf")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: { label: "RG/IE" },
                            model: {
                              value: _vm.employee.rg,
                              callback: function ($$v) {
                                _vm.$set(_vm.employee, "rg", $$v)
                              },
                              expression: "employee.rg",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              type: "date",
                              label: "Data de Nascimento",
                            },
                            model: {
                              value: _vm.employee.date_of_birth,
                              callback: function ($$v) {
                                _vm.$set(_vm.employee, "date_of_birth", $$v)
                              },
                              expression: "employee.date_of_birth",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                        [
                          _c("div", [
                            _c("label", { staticClass: "text-sm" }, [
                              _vm._v("Sexo"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "mt-2" },
                              [
                                _c(
                                  "vs-radio",
                                  {
                                    staticClass: "mr-4",
                                    attrs: { "vs-value": "male" },
                                    model: {
                                      value: _vm.employee.gender,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.employee, "gender", $$v)
                                      },
                                      expression: "employee.gender",
                                    },
                                  },
                                  [_vm._v("Masc.")]
                                ),
                                _c(
                                  "vs-radio",
                                  {
                                    staticClass: "mr-4",
                                    attrs: { "vs-value": "feminine" },
                                    model: {
                                      value: _vm.employee.gender,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.employee, "gender", $$v)
                                      },
                                      expression: "employee.gender",
                                    },
                                  },
                                  [_vm._v("Fem.")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex items-end mt-2" },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-2",
                          attrs: { icon: "MapPinIcon", svgClasses: "w-5 h-5" },
                        }),
                        _c(
                          "span",
                          { staticClass: "leading-none font-medium" },
                          [_vm._v("Endereço")]
                        ),
                      ],
                      1
                    ),
                    _c("hr"),
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["#####-###"],
                                expression: "['#####-###']",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: { label: "CEP", placeholder: "CEP" },
                            on: { keyup: _vm.searchZipCode },
                            model: {
                              value: _vm.employee.address.zip_code,
                              callback: function ($$v) {
                                _vm.$set(_vm.employee.address, "zip_code", $$v)
                              },
                              expression: "employee.address.zip_code",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v("Logradouro"),
                          ]),
                          _c("v-select", {
                            attrs: {
                              reduce: (option) => option.value,
                              clearable: "",
                              options: _vm.logradouroOptions,
                              placeholder: "Selecione",
                              dir: _vm.$vs.rtl ? "rtl" : "ltr",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "no-options",
                                fn: function ({}) {
                                  return [
                                    _vm._v(
                                      "\n                    Desculpe, nenhum resultado encontrado.\n                  "
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.employee.address.logradouro,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.employee.address,
                                  "logradouro",
                                  $$v
                                )
                              },
                              expression: "employee.address.logradouro",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: { label: "Endereço" },
                            model: {
                              value: _vm.employee.address.street,
                              callback: function ($$v) {
                                _vm.$set(_vm.employee.address, "street", $$v)
                              },
                              expression: "employee.address.street",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              label: "Número",
                              type: "number",
                              placeholder: "S/N",
                            },
                            model: {
                              value: _vm.employee.address.number,
                              callback: function ($$v) {
                                _vm.$set(_vm.employee.address, "number", $$v)
                              },
                              expression: "employee.address.number",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-2",
                  attrs: { label: "Complemento" },
                  model: {
                    value: _vm.employee.address.complement,
                    callback: function ($$v) {
                      _vm.$set(_vm.employee.address, "complement", $$v)
                    },
                    expression: "employee.address.complement",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-2",
                  attrs: { label: "Bairro" },
                  model: {
                    value: _vm.employee.address.neighborhood,
                    callback: function ($$v) {
                      _vm.$set(_vm.employee.address, "neighborhood", $$v)
                    },
                    expression: "employee.address.neighborhood",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4_ w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Cidade"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    placeholder: _vm.cityOptions.length
                      ? "Selecione"
                      : "Selecione um estado",
                    options: _vm.cityOptions,
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.employee.address.city_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.employee.address, "city_id", $$v)
                    },
                    expression: "employee.address.city_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Estado"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    options: _vm.states,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  on: {
                    input: function ($event) {
                      _vm.fillCity(_vm.employee.address.state_id),
                        (_vm.employee.address.city_id = null)
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.employee.address.state_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.employee.address, "state_id", $$v)
                    },
                    expression: "employee.address.state_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("País"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: _vm.countryOptions,
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.employee.address.country,
                    callback: function ($$v) {
                      _vm.$set(_vm.employee.address, "country", $$v)
                    },
                    expression: "employee.address.country",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "PhoneIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Contato"),
              ]),
            ],
            1
          ),
          _c("hr"),
          _c(
            "div",
            { staticClass: "vx-row" },
            [
              _vm._l(_vm.employee.phones, function (phone, i) {
                return _c(
                  "div",
                  { key: i, staticClass: "vx-col md:w-1/5 w-full" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["(##)#####-####", "(##)####-####"],
                          expression: "['(##)#####-####','(##)####-####']",
                        },
                      ],
                      staticClass: "w-full mt-2",
                      attrs: {
                        label:
                          phone.type === "phone"
                            ? "Telefone"
                            : phone.type === "mobile"
                            ? "Celular"
                            : phone.type === "other"
                            ? "Outro"
                            : "",
                      },
                      model: {
                        value: phone.phone,
                        callback: function ($$v) {
                          _vm.$set(phone, "phone", $$v)
                        },
                        expression: "phone.phone",
                      },
                    }),
                  ],
                  1
                )
              }),
              _c(
                "div",
                { staticClass: "vx-col md:w-2/5 w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.initial",
                        value: {
                          email: true,
                          required: _vm.employee.sistemAccess,
                        },
                        expression:
                          "{ email: true, required: employee.sistemAccess }",
                        modifiers: { initial: true },
                      },
                    ],
                    staticClass: "w-full mt-2",
                    attrs: {
                      label: "E-mail",
                      "data-vv-as": "E-mail",
                      type: "email",
                      name: "email",
                    },
                    model: {
                      value: _vm.employee.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.employee, "email", $$v)
                      },
                      expression: "employee.email",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("email"),
                          expression: "errors.has('email')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("email")))]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "InfoIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("CNH"),
              ]),
            ],
            1
          ),
          _c("hr"),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "Registro CNH" },
                  model: {
                    value: _vm.employee.cnh_employee,
                    callback: function ($$v) {
                      _vm.$set(_vm.employee, "cnh_employee", $$v)
                    },
                    expression: "employee.cnh_employee",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { type: "date", label: "Emissão CNH" },
                  model: {
                    value: _vm.employee.date_cnh_employee,
                    callback: function ($$v) {
                      _vm.$set(_vm.employee, "date_cnh_employee", $$v)
                    },
                    expression: "employee.date_cnh_employee",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Categoria CNH"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_200",
                  attrs: {
                    clearable: "",
                    reduce: (option) => option.value,
                    options: _vm.categoryCNHOptions,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.employee.category_cnh_employee,
                    callback: function ($$v) {
                      _vm.$set(_vm.employee, "category_cnh_employee", $$v)
                    },
                    expression: "employee.category_cnh_employee",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { type: "date", label: "Validade CNH" },
                  model: {
                    value: _vm.employee.exame_saude_cnh_employee,
                    callback: function ($$v) {
                      _vm.$set(_vm.employee, "exame_saude_cnh_employee", $$v)
                    },
                    expression: "employee.exame_saude_cnh_employee",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "InfoIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Comissões"),
              ]),
            ],
            1
          ),
          _c("hr"),
          _c(
            "div",
            { staticClass: "vx-col md:w-1/5 w-full mt-0" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v("Comissão " + _vm._s(_vm.employee.commission_type)),
              ]),
              _c(
                "vx-input-group",
                {
                  attrs: {
                    prependClasses:
                      "border border-solid border-grey border-r-0",
                  },
                },
                [
                  _c("template", { slot: "append" }, [
                    _c(
                      "div",
                      { staticClass: "append-text btn-addon" },
                      [
                        _c(
                          "vs-button",
                          { on: { click: _vm.toggleCommissionType } },
                          [_vm._v(_vm._s(_vm.employee.commission_type))]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm.employee.commission_type === "%"
                    ? _c("vs-input", {
                        key: "1__",
                        staticClass: "w-full",
                        attrs: { type: "number" },
                        model: {
                          value: _vm.employee.commission,
                          callback: function ($$v) {
                            _vm.$set(_vm.employee, "commission", $$v)
                          },
                          expression: "employee.commission",
                        },
                      })
                    : _c("vs-input", {
                        directives: [
                          {
                            name: "currency",
                            rawName: "v-currency",
                            value: _vm.currencyConfig,
                            expression: "currencyConfig",
                          },
                        ],
                        key: "2___",
                        ref: "commission",
                        staticClass: "w-full",
                        model: {
                          value: _vm.employee.commission,
                          callback: function ($$v) {
                            _vm.$set(_vm.employee, "commission", $$v)
                          },
                          expression: "employee.commission",
                        },
                      }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "InfoIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Acesso ao Sistema (Login)"),
              ]),
            ],
            1
          ),
          _c("hr"),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Dar Acesso"),
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: false,
                    reduce: (option) => option.value,
                    options: _vm.sistemAccessOptions,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.employee.sistemAccess,
                    callback: function ($$v) {
                      _vm.$set(_vm.employee, "sistemAccess", $$v)
                    },
                    expression: "employee.sistemAccess",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-2/5 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: {
                        email: true,
                        required: _vm.employee.sistemAccess,
                      },
                      expression:
                        "{ email: true, required: employee.sistemAccess }",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full mt-2",
                  attrs: {
                    label: "E-mail",
                    "data-vv-as": "E-mail",
                    type: "email",
                    name: "email",
                  },
                  model: {
                    value: _vm.employee.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.employee, "email", $$v)
                    },
                    expression: "employee.email",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("email"),
                        expression: "errors.has('email')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("email")))]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-2/5 w-full mt-2" }, [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v("Senha"),
              ]),
              _c(
                "small",
                { staticClass: "flex cursor-pointer" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-2",
                    attrs: {
                      icon: "InfoIcon",
                      svgClasses: "h-4 w-4 text-warning",
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      "A senha será criada automaticmente e enviada para o email do funcionário caso seja permitido o acesso."
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto mt-2",
                      attrs: { disabled: !_vm.validateForm },
                      on: { click: _vm.save },
                    },
                    [_vm._v("Cadastrar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4 mt-2",
                      attrs: { type: "border", color: "secondary" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("Cancelar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }