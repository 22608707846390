import axios from '@/axios.js'

export default {
  async fetchAll ({ state, commit }) {
    try {
      let roles = []
      if (!state.roles.length) {
        roles = (await axios.get(`${process.env.VUE_APP_API_URL}/roles`)).data
        commit('SET_ROLES', roles)
      }
    } catch (error) {
      return error
    }
  }
}
